<template>
	<div id="main">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">学生档案</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="u-f-item u-f-jsb head">
				<div class="u-f-item">
					<!-- <el-select v-model="condition" placeholder="校区" size="small" style="width: 150px;margin-right: 10px;" @change="changeSchool">
						<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select> -->
<!--					<el-select v-model="yearval" placeholder="学年" size="small" style="width: 150px;margin-right: 10px;" @change="changeYear">-->
<!--						<el-option v-for="item in years" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
<!--					</el-select>-->
					<!-- <el-select v-model="subjectval" placeholder="学期" size="small" style="width: 150px;margin-right: 10px;" @change="changeSemester">
						<el-option v-for="item in subjects" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select> -->
					<el-select v-model="gradeval" :clearable="true" placeholder="年级" size="small" style="width: 150px;margin-right: 10px;" @change="changeGrade">
						<el-option v-for="item in grades" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<el-select v-model="clbumval" :clearable="true" placeholder="班级" size="small" style="width: 150px;margin-right: 10px;" @change="changeClass">
						<el-option v-for="item in clbums" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="main-tab-bars u-f-item">
					<div class="main-tab-item" :class="{ active: tabIndex == 0 }" @click="tabTap(0)">直观视图</div>
					<div class="main-tab-item" :class="{ active: tabIndex == 1 }" @click="tabTap(1)">列表视图</div>
				</div>
			</div>

      <div v-if="loading" v-loading="loading" style="min-height: 300px"></div>
			<template v-if="!loading">
        <template v-if="tabIndex === 0">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="main-item">
                <div class="title">男女比例</div>
                <div class="u-f-justify" style="height: 260px;">
                  <div style="margin-right: 60px;" v-if="dataInfo.sex">
                    <Progress strokeColor="#2D8CF0" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="dataInfo.sex['1']?dataInfo.sex['1']/(dataInfo.sex['1']+dataInfo.sex['2'])*100:0">
                      <div style="font-size:26px;color: #2D8CF0;font-weight: 100;">
                        {{dataInfo.sex['1']}}
                        <span style="font-size: 12px;">人</span>
                      </div>
                      <template v-slot:footer>
                        <div class="u-f-justify" style="color: #94A3AB;">男生</div>
                      </template>
                    </Progress>
                  </div>
                  <div v-if="dataInfo.sex">
                    <Progress strokeColor="#FF7A65" :transitionDuration="1000" :radius="60" :strokeWidth="15" :value="dataInfo.sex['2']?dataInfo.sex['2']/(dataInfo.sex['1']+dataInfo.sex['2'])*100:0">
                      <div style="font-size:26px;color: #FF7A65;font-weight: 100;">
                        {{dataInfo.sex['2']}}
                        <span style="font-size: 12px;">人</span>
                      </div>
                      <template v-slot:footer>
                        <div class="u-f-justify" style="color: #94A3AB;">女生</div>
                      </template>
                    </Progress>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="main-item">
                <div class="title">年龄比例</div>
                <div style="height: 260px;padding: 0 15px;">
                  <VeHistogram v-if="chartData.rows.length" :data="chartData" :extend="chartExtend" style="height: 260px;width: 100%;"></VeHistogram>
                  <el-empty :image-size="100" v-if="!chartData.rows.length"></el-empty>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="main-item">
                <div class="title">{{gradeval?'班级':'年级'}}人数比例</div>
                <div style="height: 260px;padding: 0 15px;" v-if="gradeval"><ve-line :data="chartData2" :extend="chartExtend5" style="height: 260px;width: 100%;"></ve-line></div>
                <div style="height: 260px;padding: 0 15px;" v-if="!gradeval"><ve-line :data="chartData2" :extend="chartExtend2" style="height: 260px;width: 100%;"></ve-line></div>
              </div>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <div class="main-item">
                <div class="title">学籍/非学籍比例</div>
                <div style="height: 260px;padding: 0 15px;">
                  <ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData3"></ve-ring>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <div class="main-item">
                <div class="title">身份比例</div>
                <div style="height: 260px;padding: 0 15px;">
                  <ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData4"></ve-ring>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <div class="main-item">
                <div class="title">微信绑定比例</div>
                <div style="height: 260px;padding: 0 15px;">
                  <ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData5"></ve-ring>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
              <div class="main-item">
                <div class="title">住宿生比例</div>
                <div style="height: 260px;padding: 0 15px;">
                  <ve-ring height="240px" width="100%" legend-position="bottom" :colors="appColor" :extend="ringExtend" :settings="settings" :data="chartData6"></ve-ring>
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
        <template v-if="tabIndex === 1">
          <div class="content">
            <div class="title">共{{total}}条数据</div>
            <div style="padding:0 15px;">
              <!-- <div class="u-f-item u-f-jsb" style="padding:10px 15px;background-color: #E6F7FF;border: 1px solid #B7E3FF;margin-bottom: 15px;">
                <div>已选择0项</div>
                <div style="color: #2F9AFF;" class="mouse" @click="toggleSelection">全选</div>
              </div> -->
              <el-table ref="multipleTable" :data="List" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
                <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
                <el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
                <el-table-column
                    prop="class_name"
                    label="班级" align="center"></el-table-column>
                <el-table-column prop="realname" label="姓名" align="center"></el-table-column>
                <!-- <el-table-column prop="class_num" label="学号"></el-table-column> -->
                <el-table-column prop="student_num" label="学籍号" align="center"></el-table-column>
                <el-table-column prop="sex" label="性别" :filters="[{ text: '男', value: '1' }, { text: '女', value: '2' }]" :filter-method="filterHandler" align="center">
                  <template slot-scope="scope">
                    <div>
                      {{scope.row.sex==1?'男':'女'}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="stunature_status"
                    label="学籍性质"
                    :filters="[{ text: '本校', value: '1' }, { text: '挂学籍', value: '2' }]"
                    :filter-method="filterHandler" align="center"
                >
                  <template slot-scope="scope">
                    <div>
                      {{scope.row.stunature_status==1?'本校':'挂学籍'}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    :filters="[{ text: '在校', value: '1' }, { text: '离校', value: '2' }]"
                    :filter-method="filterHandler" align="center"
                >
                  <template slot-scope="scope">
                    <div>
                      {{scope.row.status==1?'在校':'离校'}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注" align="center"></el-table-column>
                <el-table-column label="操作"  width="85" align="center">
                  <template slot-scope="scope">
                    <el-button @click="detailsClick(scope.row)" type="text" size="small">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="u-f-right" style="padding: 5px 0;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="page"
                    :page-size="10"
                    layout="total, prev, pager, next"
                    :total="total"
                ></el-pagination>
              </div>
            </div>
          </div>
        </template>
      </template>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import Progress from 'easy-circular-progress';
export default {
	components: {
		Progress,
		index
	},
	data() {
		return {
			rank:3,
			tabIndex: 0,
			currentPage1: 10,
			condition: '',
			conditionList: [],
			yearval: '',
			years: [],
			gradeval: '',
			grades: [],
			clbumval: '',
			clbums: [],
			subjects: [],
			subjectval: '',
			chartExtend: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name: '比例',
					type: 'bar',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#58AFFF'
						}
					}
				}
			},
			chartData: {
				columns: ['name', 'values'],
				rows: []
			},
			chartExtend2: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name: '人数',
					type: 'bar',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#77DEFB'
						}
					}
				}
			},
			chartExtend5: {
				legend: {
					show: false
				},
				grid: {
					height: 180
				},
				xAxis: {
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						textStyle: {
							//文字样式
							color: '#878787'
						}
					}
				},
				series: {
					name: '人数',
					type: 'line',
					barWidth: 20,
					itemStyle: {
						normal: {
							color: '#77DEFB'
						}
					}
				}
			},
			chartData2: {
				columns: ['name', 'values'],
				rows: []
			},
			chartData3: {
				columns: ['name', 'values'],
				rows: [{ name: '本校', values:0 }, { name: '挂学籍', values:0 }]
			},
			ringExtend:{
				legend: {
					show: true,
					icon:'circle'
				},
			},
			settings: {
				radius: [45, 60],
				offsetY: 100,
				labelLine: {
					show: false
				},
				label: {
					show: false
				}
			},
			appColor: ['#2788F0', '#FF7A65', '#FFC904', '#3DCDE6', '#A190F7'],
      loading: true,
			chartData4: {
				columns: ['name', 'values'],
				rows: [{ name: '党员', values: 0 }, { name: '团员', values: 0 }, { name: '群众', values: 0 }]
			},
			chartData5: {
				columns: ['name', 'values'],
				rows: [{ name: '已绑定', values: 0 }, { name: '未绑定', values: 0 }]
			},
			chartData6: {
				columns: ['name', 'values'],
				rows: [{ name: '住宿', values: 0 }, { name: '不住宿', values: 0 }]
			},
			multipleSelection: [],
			dataInfo: {},
			List: [],
			page: 1,
			total: 0,
			dialogVisible: false,
			dialogTitle: '',
			value:'',
			value2:'',
			options:[
				{
					value:1,
					label:'一级'
				},
				{
					value:2,
					label:'二级'
				},
				{
					value:3,
					label:'三级'
				}
			]
		};
	},
	created() {},
	mounted(){
		this.getYearList();
	},
	methods: {
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] == value;
		},
		// 学年列表
		getYearList(){
			this.$api.setting.getSemesterList().then(res=>{
				if(res.data.code==1){
					this.years = res.data.data;
					if(res.data.data.length){
						if( this.years.filter(item=>item.is_default==2).length>0){
							this.yearval = this.years.filter(item=>item.is_default==2)[0].id;
						}else{
							this.yearval = this.years[0].id
						}
					}else{
						this.yearval = ''
					}	
					this.getGradeList()
					this.getStudentList()
				}
			})
		},
		changeYear(e){
			this.yearval = e;
			this.page = 1;
			this.List = [];
			this.getGradeList()
			this.getStudentList()
		},
		// 学期列表
		getSemesterList(){
			this.$api.setting.getXueQiList({year_id: this.yearval}).then(res=>{
				if(res.data.code==1){
					this.subjects = res.data.data;
					if(res.data.data.length){
						if( this.subjects.filter(item=>item.is_default==2).length>0){
							this.subjectval = this.subjects.filter(item=>item.is_default==2)[0].id;
						}else{
							this.subjectval = this.subjects[0].id
						}
					}else{
						this.subjectval = ''
					}
					
				}
			})
		},
		changeSemester(e){
			this.subjectval = e;
			this.page = 1;
			this.List = [];
			this.getStudentList()
		},
		// 年级列表
		getGradeList(){
			this.$api.setting.getGradeList({
			}).then(res=>{
				if(res.data.code===1){
					this.grades = res.data.data.rows;
					// this.gradeval = this.grades[0].id;
					if(this.gradeval){
						this.getClassList()
					}
				}
			})
		},
		// 切换年级
		changeGrade(e){
			this.gradeval = e;
			this.page = 1;
			this.List = [];
			this.getClassList()
		},
		// 班级列表
		getClassList(){
			this.$api.setting.getClassList({filter: JSON.stringify({'grade_id': this.gradeval})}).then(res=>{
				this.clbums = res.data.data.rows;
				this.clbumval = '';
				// this.clbumval = this.clbums[0].id;
				this.getStudentList()
			})
		},
		// 切换班级
		changeClass(e){
			this.clbumval = e;
			this.page = 1;
			this.List = [];
			this.getStudentList()
		},
		// 学生档案列表
		getStudentList(){
			let data = {
				page: this.page,
				limit: 10,
				// year_id: this.yearval,
				// semester_id: this.subjectval,
				grade_id: this.gradeval,
				class_id: this.clbumval,
				type: this.tabIndex
			}
      this.loading = true
			this.$api.student.studentList(data)
          .then(res => {
            if (res.data.code === 1) {
              if(this.tabIndex === 0){
                this.dataInfo = res.data.data;
                this.chartData.rows = this.dataInfo.age;
                this.chartData2.rows = this.dataInfo.grade;
                this.chartData3.rows = this.dataInfo.nature;
                this.chartData4.rows = this.dataInfo.identity;
                this.chartData5.rows = this.dataInfo.wechat;
                this.chartData6.rows = this.dataInfo.accom;
              }else{
                let list = res.data.data.rows;
                this.total = res.data.data.total;
                this.List = list;
              }
            } else {
              this.$message.error(res.data.msg);
            }
            this.$nextTick(() => this.loading = false);
          });
		},
		handleClose(done) {
			done();
		},
		// 性别筛选
		filtersex(row, column) {
			return row.sex;
		},
		// 学科筛选
		filtersubject(row, column) {
			return row.subject;
		},
		// 班级筛选
		filterclasses(row, column) {
			return row.classes;
		},
		// 性质筛选
		filternature(row, column) {
			return row.nature;
		},
		// 职称筛选
		filterrank(row, column) {
			return row.rank;
		},
		// 工资筛选
		filtersalary(row, column) {
			return row.salary;
		},
		// 身份筛选
		filteridentity(row, column) {
			return row.identity;
		},
		tabTap(index) {
			this.tabIndex = index;
			this.getStudentList()
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		toggleSelection(rows) {
			if (this.List) {
				this.List.forEach(row => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getStudentList()
			console.log(`当前页: ${val}`);
		},
		handleClick(row) {
			this.dialogTitle = row.name;
			this.dialogVisible = true;
		},
		detailsClick(row) {
			this.$router.push({path: '/studentDetails', query: {id: row.detail_id}})
		}
	}
};
</script>

<style lang="scss">
#main {
	.head {
		background-color: #ffffff;
		padding: 8px 20px;
		margin-top: 15px;
		.main-tab-bars {
			border: 1px solid #dadfe0;
			.main-tab-item {
				height: 30px;
				line-height: 30px;
				width: 100px;
				text-align: center;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			.active {
				color: #67adf4;
				background-color: #e1f3ff;
				border: 1px solid #67adf4;
			}
		}
	}
	.main-item {
		background: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
			color: #4e4e4e;
			border-bottom: 1px solid #fafafb;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 15px;
		.title {
			padding: 15px;
			font-weight: bold;
			font-size: 16px;
		}
	}
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.vue-circular-progress .circle__progress {
		stroke-linecap: square;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 20px;
		.el-radio{
			margin-bottom: 10px;
			margin-top: 10px;
		}
	}
	.el-dialog__footer{
		border-top: 1px solid #EEEEEE;
		text-align: center;
	}
	.el-table__column-filter-trigger{
		.el-icon-arrow-down:before{
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
